body{
    padding:0;
    margin:0 auto;
    background-color: #ed4c74;
}
h1{
    font-size:4em;
    color: white;
    font-family: 'Work Sans', sans-serif;
    text-shadow: #ed4c74 5px 2px, #edd94c 10px 4px;
    text-align: center;
}
p{
    font-size:1.5em;
    color:white;
    font-family: 'Work Sans', sans-serif;
    text-align: center;
    font-weight: bold;
    text-transform: uppercase;
}
.parent {
    position: absolute;
    top:50%;
    left:50%;
    transform:translate(-50%, -50%);
}